/* svg.right-arrow,
svg.left-arrow {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path d='m70.756156 6.24865598 3.314563 3.31456304-40.88125 40.87918698 40.88125 40.8796875-3.3148755 3.314563-40.8788745-40.8796875-3.314563-3.314563 3.3142505-3.3140625.0003125.0015625z' class='arrow' transform='translate(100, 100) rotate(180) '></path></svg>");
    background-repeat: no-repeat;
} */

#navbarTogglerDemo03
{
    text-align: left;
    background-color: #fff;
    opacity: 0.9;
    margin-top: -0.2rem;
}

.page-center
{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /**border: 5px solid #FFFF00;**/
    padding: 10px;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    font-family: Georgia, Georgia, serif, Georgia, Icons /*!System*/;
    font-style: normal;
    line-height: 1.3;
}


/** HAMBURGER STUFF **/

 .hamburger
{
    padding-left:15px;
} 
.hamburger-inner
{
    margin-top: 0px;
    border: none;
    width:22px;
    height: 2px !important;
    top:33%;
}

.hamburger-inner:after
{
    width:22px;
    height: 2px !important;
    border: none;
    color:blue;
}

.hamburger-inner:before
{
    width:0px;
    height: 2px !important;
    border: none;
}

/** END OF HAMBURGER STUFF **/
.slick-track {
    display: flex !important;
}

.slick-slide {
    height: inherit !important;
}

#homepage {
    cursor: pointer;
    font-size: 1.5rem !important;
}

#homepage a {
    font-size: 1.5rem !important;
}

html {
    overflow-y: scroll;
}

img {
    /* height: 67vh; */
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    visibility: hidden;
}

/* arrows */

.left-navigation {
    position: absolute;
    height: 100%;
    width: 48%;
    left: 2%;
    top: 0;
    z-index: 11;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.right-navigation {
    position: absolute;
    height: 100%;
    width: 48%;
    right: 2%;
    top: 0;
    z-index: 12;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.left-navigation:hover {
    opacity: 1;
}

.right-navigation:hover {
    opacity: 1;
}

.right-arrow {
    height: 2em !important;
    width: 2em !important;
    display: block;
    position: absolute;
    top: 50%;
    right: 5px;
    opacity: 0.7;
    cursor: pointer;
    font-size:1rem;
}

.left-arrow {
    height: 2em !important;
    width: 2em !important;
    display: block;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: scale(-1);
    opacity: 0.7;
    cursor: pointer;
    font-size:1rem;
}

.up-arrow
{
    height: 1em !important;
    width: 1em !important;
    display: block;
    position: absolute;
    transform: rotate(-90deg);
    opacity: 0.7;
    cursor: pointer;
    font-size:1rem;
    margin-top:0.75rem;
}

.slick .slick-next svg, .wallpaper-navigation .slick-next svg {
    transform: scale(0.65) !important;
    opacity: 0.75;
}

.slick .slick-prev svg, .wallpaper-navigation .slick-prev svg {
    transform: scale(-0.65) !important;
    opacity: 0.75;
}

.slick-next.image-gallery-navigation.slick-arrow {
    width: 30%;
    background-color: red;
    opacity: 0;
}

.slick-next.image-gallery-navigation.slick-arrow:hover {
    opacity: 1;
}

.slick-prev.image-gallery-navigation.slick-arrow {
    width: 30%;
    background-color: blue;
    opacity: 0;
}

.slick-prev.image-gallery-navigation.slick-arrow:hover {
    opacity: 1;
}

/* PROJECTS */

.first-image {
    /* visibility: hidden; */
}

.project {
    padding-top: 7em;
    min-height: 100vh;
    padding-bottom: 2em;
}

.first-project {
    padding-top: 5vh !important;
}

.project-title {
    cursor: pointer;
    /*font-size: 2rem;*/
    font-size: 1.5rem;
    font-weight: 400;
    color: #000;
    font-family: Georgia, Georgia, serif, Georgia, Icons/*!System*/
    ;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.01em;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
}

.ellipsis {
    font-size: 1.58rem;
    text-align: right;
    margin-left: 2em;
    line-height: 1;
    float: right;
    height: 130%;
}

.line {
    width: 45px;
    height: 1rem;
    /* border-bottom: 1.35px solid black; */
    margin-left: 2em;
    margin-right: 0px;
    right: 2px;
    text-align: right;
    float: right;
    position: absolute;
}

/* .line svg
{
    width:45px;
    height:auto;
} */

.ellipsis svg {
    height: 100%;
}

/* end of projects */

/* The Modal (background) */

.modal {
    position: fixed;
    top: auto;
    bottom: 0;
    /* Stay in place */
    z-index: 100;
    /* Sit on top */
    /*padding-top: 100px; /* Location of the box */
    left: 0;
    width: 100%;
    /* Full width */
    height: 0%;
    /* Full height */
    overflow: hidden;
    /* Enable scroll if needed */
    transition: height 0.5s ease-in-out;
    display: block;
    /* Hidden by default */
}

/* Modal Content */

.modal-content {
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    margin: auto;
    margin-bottom: 0%;
    padding: 30px;
    border: 0px;
    width: 100%;
}

.modal-text {
    overflow-y: hidden;
    /*max-height: 736px; /* approximate max height */
    padding-left: 35%;
    padding-right: 35%;
    padding-bottom: 10em;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: justify;
}

.modal-text p:first-child, .modal-text h2 {
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0px;
}

.modal-text p:first-child:hover, .modal-text h2:hover {
    opacity: 0.5;
}

.modal-text p:first-child, .modal-text h3 {
    font-size: 1rem;
    text-align: center;
}

/* The Close Button */

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

/*
 * Header / Navigation
 */

.navbar 
{
    padding-top: 0px !important;
    padding-bottom:0px !important;
    /* padding-left:2rem !important; */
    height: 3.6rem;

}
#header_page {
    position: fixed;
    top: 0px;
    z-index: 200;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    /*height: 62px;*/
    min-width: 100%;
    max-width: 100%;
    transition: top 0.5s ease-in-out;
    background-color: rgba(255, 255, 255, 0.7);
}

#header_container {
    margin-top: 0px;
}

.projects {
    margin-top: 5%;
}

.header_img {
    padding: 20px;
    text-align: center;
    z-index: 99;
}

.header_img img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.nav_container.horizontal {
    line-height: 165%;
    position: relative;
    margin: 0 auto;
    width: 100%/*905px*/
    ;
    /* padding: 0 35px; */
    padding: 0px;
    z-index: 105;
    text-align: center;
}

.nav_container.horizontal div {
    display: inline-block;
    padding: 0 75px;
}

.project_link, .page_link, .link_link, .nav_active {
    display: inline-block;
    margin: 0 0;
}

.nav_container {
    text-align: center;
}

.nav_container div {
    display: inline-block;
    padding: 0 10px;
}

#nav_loadspin {
    display: none;
    position: fixed;
    top: 8px;
    left: 8px;
    z-index: 100;
}

.nav_container br {
    display: none;
}

.nav_container a {
    font-family: Georgia, Georgia, serif, Georgia, Icons/*!System*/
    ;
    text-decoration: none;
}

.project_link a {
    color: #000000;
}

.project_link a:hover {
    color: #000000;
    text-decoration: underline;
}

.project_link a:active {
    color: #999999;
}

.page_link a {
    color: #000000;
}

.page_link a:hover {
    color: #000000;
    text-decoration: underline;
}

.page_link a:active {
    color: #999999;
}

.link_link a {
    color: #000;
    text-decoration: none;
    font-size: 1.5rem;
}

.link_link a:hover {
    color: #222;
}

.link_link a:active {
    color: #666666;
}

.nav_active a {
    color: #000000;
    font-weight: bold;
}

.nav_active a:hover {
    color: #000000;
}

.nav_active a:active {
    color: #000000;
}

.nav_follow {
    color: #999999;
    font-family: Georgia, Georgia, serif, Georgia, Icons/*!System*/
    ;
    z-index: 11;
}

.nav_follow a {
    color: #222222;
}

.nav_follow a:hover {
    color: #000000;
    text-decoration: underline;
}

.nav_follow a:active {
    color: #999999;
}

.nav_container .view_tag_info {
    color: #666666;
    display: block;
    font-family: Georgia, Georgia, serif, Georgia, Icons/*!System*/
    ;
    font-size: 14px;
    margin: 10px 0 20px 0;
}

.view_tag_info a {
    color: #666666;
    text-decoration: underline;
}

.view_tag_info a:hover {
    color: #333333;
}

.view_tag_info a:active {
    color: #999999;
}

/**
 * Content
 */

body {
    background-color: #fff;
    color: #000;
    font-family: Georgia, Georgia, serif, Georgia, Icons/*!System*/
    ;
}

a:active {
    opacity: 0.7;
}

.page a.active {
    color: rgba(0, 0, 0, 0.85);
}

i, em {
    font-style: italic;
}

b, strong {
    font-weight: bold;
}

sub, sup {
    font-size: 0.4em;
    position: relative;
    vertical-align: baseline;
}

sub {
    top: 0.3em;
}

sup {
    bottom: 1.1em;
    font-style: normal;
    font-weight: 400;
}

s {
    text-decoration: line-through;
}

/* img {
    border: 0;
    padding: 0;
} */

ul, ol {
    margin: 0;
    padding: 0 0 0 2rem;
}

blockquote {
    margin: 0;
    padding: 0 0 0 2em;
}

hr {
    background: rgba(0, 0, 0, .15);
    border: 0;
    height: 1px;
    display: block;
}

.content img {
    float: none;
    margin-bottom: 0.5rem;
    vertical-align: bottom;
}

.gallery_image_caption {
    margin-top: 1.2em;
    font-size: 1.5rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.75);
    font-family: Standard, Icons/*!Persona*/
    ;
    font-style: normal;
    line-height: 1.3;
    text-align: left;
    letter-spacing: 0.02em;
}

/**
 * Loading Animation
 */

.loading[data-loading] {
    position: fixed;
    bottom: 8px;
    left: 8px;
}

/**
 * Editor styles
 */

[data-predefined-style="true"] bodycopy {
    font-size: 2rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    font-family: Georgia, Georgia, serif, Georgia, Icons/*!System*/
    ;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.01em;
}

[data-predefined-style="true"] bodycopy a {
    color: #000;
    padding-bottom: 0.1em;
    border-bottom: 0.0em solid rgba(127, 127, 127, 0.2);
    text-decoration: none;
}

bodycopy a.image-link, bodycopy a.icon-link, bodycopy a.image-link:hover, bodycopy a.icon-link:hover {
    border-bottom: 0;
    padding-bottom: 0;
}

[data-predefined-style="true"] h1 {
    font-family: Georgia, Georgia, serif, "Georgia Italic", Icons/*!System*/
    ;
    font-style: italic;
    font-weight: 400;
    padding: 0;
    margin: 0;
    font-size: 4.2rem;
    line-height: 1.3;
    color: rgba(0, 0, 0, 0.85);
}

[data-predefined-style="true"] h1 a {
    color: #2351fc;
}

[data-predefined-style="true"] h2 {
    font-family: Georgia, Georgia, serif, "Georgia Italic", Icons/*!System*/
    ;
    font-style: italic;
    font-weight: 400;
    padding: 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 2rem;
    line-height: 1.2;
    letter-spacing: 0.01em;
}

[data-predefined-style="true"] h2 a {
    color: #2351fc;
}

[data-predefined-style="true"] small {
    display: inline-block;
    font-size: 1.5rem;
    line-height: 1.4;
    font-family: Standard, Icons/*!Persona*/
    ;
    font-style: normal;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 0.01em;
}

[data-predefined-style="true"] small a {
    color: rgba(0, 0, 0, 0.7);
    border-bottom-width: 0em;
}

/**
 * Breakpoints
 */

[data-css-preset] .page {
    background-color: initial/*!page_bgcolor*/
    ;
}

.mobile .page, [data-css-preset].mobile .page {
    position: relative;
    min-height: 10px;
    max-width: 100%;
    width: 100%;
    background-color: transparent/*!page_bgcolor*/
    ;
}

[data-css-preset] .container {
    margin-left: auto/*!content_center*/
    ;
    margin-right: auto/*!content_center*/
    ;
    text-align: left/*!text_left*/
    ;
}

[data-css-preset] body {
    background-color: rgb(255, 255, 255)/*!body_bgcolor*/
    ;
}

[data-css-preset] .container_width {
    width: 100%/*!content_center*/
    ;
}

[data-css-preset] .content_padding {
    padding-top: 1rem/*!main_margin*/
    ;
    padding-bottom: 1rem/*!main_margin*/
    ;
    padding-left: 1rem/*!main_margin*/
    ;
    padding-right: 1rem/*!main_margin*/
    ;
}

[data-css-preset] text-limit {
    display: inline-block/*!text_width*/
    ;
    max-width: 66rem/*!text_width*/
    ;
}

/**
 * Thumbnails
 */

div[thumbnails] {
    justify-content: flex-start;
}

[data-css-preset] .thumbnails {
    background-color: rgba(0, 0, 0, 0)/*!thumbnails_bgcolor*/
    ;
}

[data-css-preset] .thumbnails_width {
    width: 80%/*!thumbnails_width*/
    ;
}

[data-css-preset] [thumbnails-pad] {
    padding: 0.75rem/*!thumbnails_padding*/
    ;
}

[data-css-preset] [thumbnails-gutter] {
    margin: -1.5rem/*!thumbnails_padding*/
    ;
}

[data-css-preset] [responsive-layout] [thumbnails-pad] {
    padding: 0.5rem/*!responsive_thumbnails_padding*/
    ;
}

[data-css-preset] [responsive-layout] [thumbnails-gutter] {
    margin: -1rem/*!responsive_thumbnails_padding*/
    ;
}

.thumbnails .thumb_image {
    outline: 0px solid rgba(0, 0, 0, .12);
    outline-offset: -1px;
}

.thumbnails .title {
    margin-top: .8rem;
    margin-bottom: .2rem;
    font-size: 2rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    font-family: Standard, Icons/*!Persona*/
    ;
    font-style: normal;
    line-height: 1.3;
}

.thumbnails .tags {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    font-family: Standard, Icons/*!Persona*/
    ;
    font-style: normal;
    line-height: 1.3;
}

.thumbnails .tags a {
    border-bottom: 0;
    color: rgba(0, 0, 0, 0.35);
    text-decoration: none;
}

.thumbnails .has_title .tags {
    margin-top: 0rem;
}

/**
 * Site Menu
 */

[data-css-preset] #site_menu_button {
    color: rgba(0, 0, 0, 0.75);
    line-height: 1;
    font-size: 28px/*!site_menu_button*/
    ;
    padding: 6px;
    line-height: 1;
    background: rgba(33, 32, 46, 0);
    position: fixed;
    top: 3rem/*!site_menu_button*/
    ;
    left: 3rem/*!site_menu_button*/
    ;
}

body.mobile #site_menu_button {
    margin: -6px;
    font-size: 34px;
}

#site_menu_button.custom_icon {
    width: 40px;
    height: auto;
}

#site_menu_button.active {
    display: none;
}

/**
 * Site Menu
 */

#site_menu {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, "Sans Serif", Icons/*!System*/
    ;
    background: rgba(20, 20, 20, 0.95);
    background: rgba(255, 255, 255, 0.85);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    padding: 20px 30px 90px 30px;
    max-width: 400px;
    min-width: 300px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    color: black;
}

body.mobile #site_menu {
    width: 100%;
}

#site_menu .page-link a {
    color: rgba(255, 255, 255, 0.75);
    color: black;
}

#site_menu .set-link>a {
    color: rgba(255, 255, 255, 0.75);
    color: black;
    font-weight: bold;
}

#site_menu a:active {
    opacity: .7;
}

#site_menu a.active {
    opacity: .4;
}

#site_menu .close {
    display: none;
    color: rgba(255, 255, 255, 0.4);
    color: black;
    line-height: .85em;
    font-size: 45px;
}

body.mobile #site_menu .close {
    display: block;
    font-size: 50px;
    line-height: 1em;
}

#site_menu .break {
    height: 28px;
}

#site_menu .indent {
    margin-left: 28px;
}

/**
 * Shop Button
 */

[data-css-preset] #shop_button {
    color: rgba(0, 0, 0, 0.85);
    background: transparent;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    position: fixed;
    padding: 6px;
    top: 1.5rem/*!shop_button*/
    ;
    right: 2rem/*!shop_button*/
    ;
}

#shop_button.text {
    font-family: Standard, Icons/*!Persona*/
    ;
    font-size: 2rem;
    padding: 0;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    font-style: normal;
    line-height: 1.2;
}

#shop_button.custom_icon {
    width: 40px;
    height: auto;
}

body.mobile #shop_button:not(.text) {
    margin: -6px;
    font-size: 36px;
}

/**
 * Shop Product Widget
 */

.shop_product {
    width: 100%;
    max-width: 22rem;
    position: relative;
    display: block;
}

.shop_product .price {
    font-family: Standard, Icons/*!Persona*/
    ;
    font-size: 2.4rem;
    line-height: 1;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    margin-bottom: 1rem;
    font-style: normal;
    font-weight: 400;
}

.shop_product .dropdown {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, "Sans Serif", Icons/*!System*/
    ;
    font-size: 1.4rem;
    display: inline-block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .2);
    background: white url(https://static.cargo.site/assets/images/select-arrows.svg) no-repeat right;
    margin-bottom: 1rem;
    line-height: 1.2;
    padding: .7rem 1rem;
    font-style: normal;
    font-weight: 400;
}

.shop_product .button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, "Sans Serif", Icons/*!System*/
    ;
    font-size: 1.4rem;
    background: rgba(0, 0, 0, 0.7);
    color: rgba(255, 255, 255, 1);
    flex: 0 0 50%;
    text-align: left;
    display: inline-block;
    line-height: 1;
    padding: .8rem 1rem .9rem;
    font-style: normal;
    font-weight: 400;
}

/**
 * Image Zoom
 */

.content img.image-zoom:active {
    opacity: .7;
}

.image-zoom-background {
    background: rgba(0, 0, 0, 0.8);
}

/**
 * Image Zoom Navigation Arrows
 */

.image-zoom-navigation .left-arrow, .image-zoom-navigation .right-arrow {
    /* Change height/width together to scale */
    height: 36px;
    width: 36px;
}

.image-zoom-navigation .left-arrow .inner-color, .image-zoom-navigation .right-arrow .inner-color {
    fill: #fff;
}

.image-zoom-navigation .left-arrow .outer-color, .image-zoom-navigation .right-arrow .outer-color {
    fill: #000;
    opacity: 0.2;
}

.image-zoom-navigation .close-button {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* Change height/width together to scale */
    width: 36px;
    height: 36px;
}

.image-zoom-navigation .close-button .inner-color {
    fill: #fff;
}

.image-zoom-navigation .close-button .outer-color {
    fill: #000;
    opacity: 0.2;
}

/**
 * Image Gallery Navigation Arrows
 */

.image-gallery-navigation .left-arrow, .image-gallery-navigation .right-arrow {
    /* Change height/width together to scale */
    height: 56px;
    width: 56px;
}

.image-gallery-navigation .left-arrow .inner-color, .image-gallery-navigation .right-arrow .inner-color {
    /*fill: rgba(0, 0, 0, 0.85);*/
    fill: rgba(0, 0, 0, 0);
}

.image-gallery-navigation .left-arrow .outer-color, .image-gallery-navigation .right-arrow .outer-color {
    fill: #000;
    opacity: 0;
}

/**
 * Wallpaper Backdrop Navigation Arrows
 */

.wallpaper-navigation .left-arrow, .wallpaper-navigation .right-arrow {
    /* Change height/width together to scale */
    width: 56px;
    height: 56px;
}

.wallpaper-navigation .left-arrow .inner-color, .wallpaper-navigation .right-arrow .inner-color {
    fill: #fff;
}

.wallpaper-navigation .left-arrow .outer-color, .wallpaper-navigation .right-arrow .outer-color {
    fill: #000;
    opacity: 0.2;
}

/**
 * Feed
 */

.feed .content_container .page {
    border-top: 0px dashed rgba(0, 0, 0, 0.2);
}

.feed .content_container .page_container:first-child .page {
    border-top: 0;
}

/**
 * Audio Player
 */

.audio-player {
    max-width: 26rem;
    height: 3.3rem;
    outline: 1px solid rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.6);
    background: transparent;
    font-size: 1.2rem;
    line-height: 1.3;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, "Sans Serif", Icons/*!System*/
    ;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    margin: 1px 1px 1em 1px;
}

body.mobile .audio-player {
    max-width: 100%;
}

.audio-player .separator {
    width: 1px;
    background-color: rgba(0, 0, 0, 0.15);
}

.audio-player .button {
    background: transparent;
    cursor: pointer;
    fill: rgba(0, 0, 0, 0.85);
}

.audio-player .icon {
    fill: rgba(0, 0, 0, 0.85);
    padding: 30%;
    width: 100%;
    margin: auto;
}

.audio-player .buffer {
    background: rgba(0, 0, 0, 0.03);
}

.audio-player .progress {
    background: rgba(0, 0, 0, 0.1);
}

.audio-player .progress-indicator {
    border: 1px solid rgba(0, 0, 0, 0.7);
    width: 1px;
    height: 100%;
    right: 0;
    position: absolute;
    cursor: ew-resize;
}

.audio-player .note-icon {
    height: 100%;
    width: 3.8rem;
    padding: 1rem;
    fill: rgba(0, 0, 0, 0.5);
}

.audio-player .current-time {
    padding-left: 1rem;
}

.audio-player .total-time {
    padding-right: 1rem;
}

button:focus 
{
    outline: none;
}

/* BREAKPOINTS */
@media (max-width: 576px)
{
    img {
        width: 100vw;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        vertical-align: middle;
        visibility: hidden;
    }
}


@media (max-width: 992px) {
    img {
        width: 90vw;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        vertical-align: middle;
        visibility: hidden;
    }
    .navbar-toggler-icon
    {
        background-image: unset !important;
    }

    .navbar-toggler
    {
        color: black !important;
        border: 0px !important;
        width: 30px;
    }

    

    

    nav {
        font-family: georgia;
    }
    /*.navbar-brand 
    {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }*/

    .navbar-brand
    {
        padding-right: 22.5px;
    }

    /*#header_page {
        display: none;
    }
    #mobile-header {
        display: block;
    }*/
}

/* mobile only */

@media only screen and (max-width: 780px) {
    .hamburger
    {
        padding-left:0px;
    }

    .hamburger-inner,.hamburger-inner:before,.hamburger-inner:after
    {
        height: 1.5px !important;
    }

    .modal-text {
        padding-left: 10px !important;
        padding-right: 10px !important;
        text-align: left;
    }

    .project-title
    {
        font-size: 1rem;
    }

    .up-arrow
    {
        height: 1.125em !important;
        width: 1.125em !important;
        margin-top:0rem;
    }

    .left-navigation, .right-navigation 
    {
        width:0%;
    }

    #homepage
    {
        font-size:1rem !important;
        padding-bottom:10px;
    }

    .page-center-long-text
    {
        top: 10%;
        transform: translate(-50%, 0%);
        width:90%;
    }

    .first-project {
        padding-top: 10vh !important;
    }
}

/* LARGE SCREENS */

@media (min-width: 992px) {
    img {
        height: 67vh;
        margin-left: auto;
        margin-right: auto;
        vertical-align: middle;
        visibility: hidden;
    }
    /*#mobile-header {
        display: block;
    }*/
    #header_page {
        display: block;
    }

    .navbar-brand
    {
        padding-right: 55px;
    }
}